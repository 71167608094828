import {Component} from 'react';
import * as Sentry from '@sentry/node';
import styles from './styles.module.scss';
import {Button} from './common-components';
import {NextImg} from '@/utils/nextImg';
import {openLiveChat} from '@/utils/intercom';
import {getDomain} from '@/utils/url';
import {linkgraphDomains} from '@/utils/router';
export class ErrorBoundary extends Component<{children: any}, {hasError: boolean; isWhiteLabelDomain: boolean}> {
  constructor(props) {
    super(props);

    const currentDomain = getDomain(window.location.hostname);
    const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);

    this.state = {
      hasError: false,
      isWhiteLabelDomain,
    };
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    Sentry.captureException(error);
  }


  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.errorPage}>
          <div className={styles.errorPageContainer}>
            <NextImg src='/img/error-bird.svg' alt='bird-icon' width={100} height={100} />
            <h1>Uh Oh!</h1>
            <p>Something went wrong at our end.
              We’ll be up and running soon.</p>
            <div className={styles.errorPageContainerActions}>
              {!this.state.isWhiteLabelDomain && <Button buttonType='primary'
                onClick={()=>{
                  openLiveChat();
                }}
              >
                Talk to our team
              </Button>}
              <Button
                className={this.state.isWhiteLabelDomain ? styles.transparentBtn : ''}
                onClick={()=> window.location.reload()}
                buttonType='transparent'>
                Refresh page
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
