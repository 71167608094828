import React, {useCallback, useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {Col, Form} from 'antd';
import {linkgraphDomains, openUrl} from '@/utils/router';
import {AccountInput, ContinueButton, FormContainer, StyledFormItem, Terms, onlyLettersReg, onlySpacesReg, HeadingAnimationWrapper} from './helpers';
import GoogleAuthButton from '@/components/common-components/components/google-auth-button';
import {getDomain, getSingleUrlParam} from '@/utils/url';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import styled from 'styled-components';
import {useTranslation} from 'next-i18next';
import Link from 'next/link';
import {authApi} from '@/api/account';
import {notification} from '@/utils/notifications';
import {apiError} from '@/utils/api';
import {metaClickEvents} from '@/utils/functions';
import {updateGTMDatalayer} from '@/utils/gtm';
import {NextImg} from '@/utils/nextImg';
import {LogoWrapper} from '../components/logo-wrapper/logoWrapper';

const StepOne = observer(({setStepData, stepData}) => {
  const [form] = Form.useForm();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const {settings: {customer: {profile: {logo, signUpLogo}}}} = useStore('');
  const {t} = useTranslation('common');
  const invitationToken = getSingleUrlParam('invitation_token');
  const [emailDisabled, setEmailDisabled] = useState(false);

  const {settings: {customer: {profile: {domainApiLoading, brandColor}}}} = useStore('');

  const loadEmail = async () => {
    if (invitationToken) {
      setEmailDisabled(true);
      try {
        const response = await authApi.loadRegisterEmail(invitationToken);
        form.setFieldsValue({
          email: response?.data?.email || '',
        });
      } catch (e) {
        const errorMessage = apiError(e);
        notification.error(errorMessage, 'topRight');
      }
    }
  };

  useEffect(() => {
    loadEmail();
  }, []);

  const handleGoogleRedirect = useCallback(
    () => {
      openUrl(`https://api.searchatlas.com/account/google/oauth/start?from_domain=${window.location.host}`, '_blank');
    },
    [],
  );

  return (
    <>
      {(isWhiteLabelDomain && domainApiLoading) ? <></> : <div className={styles.signUpBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <div
          className={styles.bgGradient}
        />
        <div
          style={{height: '22px'}}
        />
        <LogoWrapper>
          <img className={invitationToken ? styles.invitationLogo : styles.logo} style={{zIndex: 3, width: '298px', height: 'auto', marginBottom: invitationToken ? '' : '98px', cursor: isWhiteLabelDomain && 'pointer'}} src={isWhiteLabelDomain ? (signUpLogo ?? logo ) : '/img/registerBg/newRegisterLoginLogo.svg'} />
        </LogoWrapper>
        {invitationToken ? <div className={styles.invitationTitle}>Accept your Invitation</div> : <div className={styles.descriptionContainer}>
          <div className={styles.container}>
            <HeadingAnimationWrapper>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <p className={styles.text}>Automate your SEO</p>&nbsp;
                {isWhiteLabelDomain ? '' : <div className={styles.descLineTwo}>with Search Atlas.</div>}
              </div>
            </HeadingAnimationWrapper>
            <div id='console' className='console-underscore'></div>
          </div>
          <div className={styles.descriptionWrapper}>
            <div className={styles.description}>37 cutting-edge SEO, public relations and backlink tools to start ranking quickly.</div>
            <div className={styles.description}>Stop wasting time and money on your SEO and transform your strategy in 7 days.</div>
          </div>
        </div>}
        <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
          {!invitationToken && <div className={styles.formTitle}>Enter your email to start your 7-day free trial.</div>}
          <StyledForm style={{marginTop: invitationToken ? '30px' : ''}} className={styles.form} form={form}
            onFinish={(values: any) => {
              setStepData({step: 'stepTwo', data: {...stepData.data, ...values}});
              metaClickEvents('click_register_step_1', {step: 'step 1', email: values?.email, contactName: values?.contactName});
              updateGTMDatalayer({'event': 'email_added', 'step': 'step 1', 'email': values?.email, 'contactName': values?.contactName});
            }}
            initialValues={{contactName: stepData?.data ? stepData.data.contactName : '', email: stepData?.data ? stepData.data.email : ''}}
          >
            <Col>
              <Col>
                <div className={styles.label}>Name</div>
              </Col>
              <Col span={24}>
                <StyledFormItem
                  name='contactName'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your name',
                    },
                  ]}
                >
                  <AccountInput
                    type='text'
                    onChange={e=>{
                      const chars = e.target.value.split('');
                      const char = chars.pop();
                      if (!onlyLettersReg.test(char) && !onlySpacesReg.test(char)) {
                        e.target.value = chars.join('');
                      }
                    }}
                    placeholder={'Type your name'}
                  />
                </StyledFormItem>
              </Col>
            </Col>
            <Col className={styles.emailInput} >
              <Col>
                <div className={styles.label}>Email</div>
              </Col>
              <Col>
                <StyledFormItem
                  name='email'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter an email',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <AccountInput
                    data-cy='emailInput'
                    disabled={emailDisabled}
                    type='email'
                    placeholder={'Type your email address'}
                  />
                </StyledFormItem>
              </Col>
            </Col>
            <Col>
              <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} htmlType='submit' style={{marginBottom: isWhiteLabelDomain ? '50px' : '0px'}}
              >
                <div>
                  {invitationToken ? 'Activate Your Account' : 'Continue with email'}
                </div>
                <NextImg src='/img/registerBg/searchAtlasIcon.svg' width={16} height={16} />
              </ContinueButton>
            </Col>
            {invitationToken ? null : <>
              {!isWhiteLabelDomain && <><Col>
                <div className={styles.or}>OR</div>
              </Col>
              <Col>
                <GoogleAuthButton onClick={handleGoogleRedirect} />
              </Col></>}
            </>}
            {!isWhiteLabelDomain && <Col>
              <Terms>
                <div> By continuing, you agree to Search Atlas’s <a href='https://www.linkgraph.com/terms-of-service/' target='_blank' rel='noreferrer'>Consumer</a></div>
                <div><a href='https://www.linkgraph.com/terms-of-service/' target='_blank' rel='noreferrer'>Terms</a> and acknowledge their <a href='https://www.linkgraph.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy</a>.</div>
              </Terms>
            </Col>}
          </StyledForm>
        </FormContainer>
        <Col>
          <p className={styles.logIn} style={{marginBottom: isWhiteLabelDomain ? '47px' : ''}}>
            {t('already-have-account')}{' '}<Link href='/login'>{t('log-in')}</Link>
          </p>
        </Col>
        <div className={styles.footerTxtContainer}>
          <div className={invitationToken ? styles.footerTxtInvitation : styles.footerTxt}>Content. Technicals. Automation.</div>
          <div className={invitationToken ? styles.footerTxtInvitation : styles.footerTxt}>Everything you need for SEO in one place.</div>
        </div>
      </div>}
    </>
  );
});

export default StepOne;

export const StyledForm = styled(Form)`
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;
