import React from 'react';
import styles from './styles.module.scss';
import {Col, Form, Spin} from 'antd';
import {ContinueButton, FormContainer, StyledFormItem, Terms} from './helpers';
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2';
import {LoadingOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {linkgraphDomains} from '@/utils/router';
import {getDomain} from '@/utils/url';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {metaClickEvents} from '@/utils/functions';
import {LogoWrapper} from '../components/logo-wrapper/logoWrapper';


const StepThree = ({setStepData, stepData, onRegister, loading}) => {
  const [form] = Form.useForm();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const {settings: {customer: {profile: {brandColor, logo, signUpLogo}}}} = useStore('');

  return (
    <>
      <div className={styles.changeBg}>
        {(isWhiteLabelDomain && brandColor) && (
          <>
            <div className={styles.bgBack}></div>
            <div className={styles.bgFront} style={{'--brand-color': brandColor} as any}></div>
          </>
        )}
        <FontAwesomeIcon
          icon={faArrowLeft}
          color='#ffff'
          fontSize={30}
          style={{padding: 20, opacity: 0.5, cursor: 'pointer', zIndex: 2, alignSelf: 'start'}}
          onClick={() => setStepData({step: 'stepTwo', data: {...stepData.data}})}
        />
        <div className={styles.formContainer}>
          <div
            style={{height: '22px'}}
          />
          <LogoWrapper>
            <img className={styles.logo} style={{zIndex: 3, width: '298px', height: 'auto', cursor: isWhiteLabelDomain && 'pointer'}} src={isWhiteLabelDomain ? (signUpLogo ?? logo ) : '/img/registerBg/newSearchatlasLogo.png'} />
          </LogoWrapper>
          <div className={styles.stepTwoHeading}>
            <div className={styles.descLineOne}>One more step!</div>
          </div>
          <FormContainer brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''}>
            <div className={styles.formTitle}>What&apos;s your phone number?</div>
            <StyledForm className={styles.form} form={form}
              onFinish={(values: any) => {
                setStepData({...stepData, data: {...stepData.data, ...values}});
                metaClickEvents('click_register_step_3', {step: 'step 3', phone: values.phoneNumber});
                onRegister(values.phoneNumber);
              }}
              initialValues={{phoneNumber: stepData?.data ? stepData.data.phoneNumber : ''}}
            >
              <Col span={24}>
                <Col>
                  <div className={styles.label}>Phone number</div>
                </Col>
                <StyledFormItem
                  className={styles.phoneInput}
                  style={{marginBottom: '5px'}}
                  name='phoneNumber'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter phone number',
                    },
                  ]}
                >
                  <StyledPhoneInput
                    country={'us'}
                    inputStyle={{width: '100%'}}
                    placeholder={'Phone Number'}
                    onChange={e => setStepData({...stepData, data: {...stepData.data, phoneNumber: e}})}
                    value={stepData.data.phoneNumber}
                  />
                </StyledFormItem>
              </Col>
              <Col>
                <ContinueButton brandColor={(isWhiteLabelDomain && brandColor) ? brandColor : ''} htmlType='submit' style={{marginBottom: '0px', marginTop: '18px'}} disabled={loading} loadingStyle={loading ? 'opacity: 0.7;' : ''}>
                  <div>Submit</div>
                  {loading ? <Spin indicator={<LoadingOutlined style={{color: '#ffff', fontSize: '20px'}} spin />} /> : <Image alt='searchAtlasIcon' src='/img/registerBg/searchAtlasIcon.svg' width={16} height={16} />}
                </ContinueButton>
              </Col>
              <Col>
                <Terms style={{marginTop: '20px'}}>
                  <div>SMS & data charges may apply.</div>
                  <div>You can unsubscribe at any time.</div>
                </Terms>
              </Col>
            </StyledForm>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default StepThree;

const StyledPhoneInput = styled(PhoneInput)`
  .country-list {
    text-align: start !important;
  }
  .form-control::placeholder {
    color: #FFFFFF !important;
    opacity: 1;
  }
`;

export const StyledForm = styled(Form)`
  @media screen and (max-width: 600px) {
    width: 90% !important;
  }
`;
