
import {getTokenFromCookies} from '@/api/common-utils';
import {getDomain} from '@/utils/url';
import {linkgraphDomains} from '@/utils/router';
import {useRouter} from 'next/router';
import {routes} from '@/utils/const';

export const LogoWrapper = props => {
  const router = useRouter();
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);

  const routeToHomePage = () => {
    if (!isWhiteLabelDomain) {
      if (getTokenFromCookies()) {
        router.push(routes?.home);
      } else {
        window.location.href = 'https://searchatlas.com';
      }
    }
  };

  return (
    <div id='logo-wrapper' onClick={routeToHomePage} style={{cursor: !isWhiteLabelDomain && 'pointer'}}>
      {props.children}
    </div>
  );
};
